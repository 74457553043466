'use client';

import { OveralPageProps } from './type';
import { OveralWrapperLabel } from '../BlockTitle/OveralWrapperLabel';
import { dedupeById } from '@/utils/data/dedupeById';
import { Block, BlockElement } from '@/lib/model';
import { useState } from 'react';
import { RadioPlaylistCard } from '../../cards/radio/RadioPlaylistCard';
import { ArticleSectionWrapper } from '../PageWrapper';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { RadioContent } from '../../cards/radio/RadioContent';

export const ListenRadioLarge = ({ block }: OveralPageProps) => {
  const channel = useGetChannelPref();
  const blockElements = dedupeById(
    (block as Block)?.blockElements as Array<BlockElement>,
  )?.filter(c => c?.id);
  const [selectedBlock, setSelectedBlock] = useState(blockElements[0]);

  if (!blockElements?.length) return null;
  const title = selectedBlock?.radioPlaylist?.title;
  return (
    <div className="w-full flex flex-col gap-8">
      <ArticleSectionWrapper
        data-block="listen_radio_large-authors"
        key={block.id}
        className="grid gap-3 md:gap-5 xl:gap-7"
      >
        <OveralWrapperLabel title={block?.name} />
        <RadioContent
          title={title}
          radioPlaylistId={selectedBlock?.radioPlaylist?.id}
          channel={channel}
        />
      </ArticleSectionWrapper>
      <RadioPlaylistCard
        blockElements={blockElements}
        onSelectedBlock={setSelectedBlock}
      />
    </div>
  );
};
