'use client';

import { useRef } from 'react';

import { Content, Topic } from '@/lib/model';
import {
  getMediaIconType,
  getOptimizedImageFromCoverImage,
} from '@/common/utils';
import Image from 'next/image';
import {
  AudioPlayerWithTimer,
  PlayerWithTimerRef,
} from '../../player/PlayerWithTimer';
import dayjs from 'dayjs';

type Props = {
  content?: Content;
  isAudioCarousel?: boolean;
  hideDetailPageArrow?: boolean;
};

export function MediaCardRadio({
  content,
  hideDetailPageArrow,
  isAudioCarousel = true,
}: Props) {
  const { title, description, createdAt } = content || {};
  const audioRef = useRef<PlayerWithTimerRef | null>(null);

  const image = getOptimizedImageFromCoverImage(content?.coverImage, 200, 200);

  const { isVideo, isAudio } = getMediaIconType(content);

  const primaryTopic = (content?.primaryTopics as Topic)?.title;

  return (
    <div>
      <div
        className="w-full h-full aspect-square rounded-custom overflow-hidden bg-no-repeat relative cursor-pointer show-mini-player video-container"
        data-title={title || ''}
        data-topic={primaryTopic || ''}
        data-description={description || ''}
        data-is-audio={isAudioCarousel}
        data-background={image}
        data-vjs-player
      >
        <Image
          src={image}
          alt={content?.title || ''}
          fill
          className="object-cover h-full w-full"
          quality={80}
        />
        <div
          onClick={() => {
            audioRef.current?.togglePlay();
          }}
          className="w-full h-full absolute bottom-0 bg-gradient-to-b -from-[0%] to-[100%] from-[#00000000] to-[#000000E5] rounded-custom"
        />

        <div className="absolute bottom-4 left-4 right-4 flex flex-col gap-4 z-10">
          {(isVideo || isAudio) && content ? (
            <AudioPlayerWithTimer
              content={content as Content}
              image={image}
              ref={audioRef}
              hideDetailPageArrow={hideDetailPageArrow}
            />
          ) : null}
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <p className="px-1 text-grey-12 bg-grey-5 self-start font-extrabold text-black-10 text-base font-playfair">
          {dayjs(createdAt).format('DD.MM.YYYY')}
        </p>
        <div
          onClick={() => {
            audioRef.current?.togglePlay();
          }}
          className="font-playfair xl:text-xl xl:leading-7 lg:text-lg lg:leading-6 md:text-base md:leading-5.5 text-xl leading-7 font-semibold text-grey-12 line-clamp-4"
        >
          {title}
        </div>
      </div>
    </div>
  );
}
