'use client';

import { useDeviceType } from '@/hooks/useIsDesktop';
import { LiveTvWrapper } from './LiveTvWrapper';

export const LiveTvDesktop = () => {
  const { isDesktop } = useDeviceType();

  if (isDesktop) return <LiveTvWrapper />;
};

export const LiveTvMobile = () => {
  const { isDesktop } = useDeviceType();

  if (!isDesktop) return <LiveTvWrapper />;
};
