'use client';

import { AudioNarrationAnimation } from '@/app/components/animations/audio-wave/AudioNarrationAnimation';
import { PlayIcon as DefaultPlayIcon, PauseIcon } from '@/app/components/icons';
import { useAudioPlayerWithCountDown } from '@/app/components/player/useAudioPlayerWithCountDown';
import { Content } from '@/lib/model';
import { cn } from '@/lib/utils';
import { formatDuration } from '@/utils/time/formatDuration';
import { useTranslations } from 'next-intl';
import { ReactNode, useMemo, useState } from 'react';
import { AnimateSpinIcon } from '../../icons/AnimateSpinIcon';

interface Props extends Pick<Content, 'audioNarration' | 'duration'> {
  variant?: 'light' | 'dark';
  PlayIcon?: ReactNode;
  noStatusText?: boolean;
  keyID?: string;
}

export const AudioNarration = ({
  audioNarration,
  duration,
  variant = 'dark',
  PlayIcon = <DefaultPlayIcon />,
  noStatusText,
  keyID,
}: Props) => {
  const t = useTranslations();

  const [playText, setPlayText] = useState(t('playNarration'));
  const { colorClassName, animationColor } = useMemo(
    () => ({
      colorClassName: variant === 'dark' ? 'text-black' : 'text-white',
      animationColor: variant === 'dark' ? '#000000' : '#FFFFFF',
    }),
    [variant],
  );
  const {
    onEnded,
    timeLeft,
    togglePause,
    isPlaying,
    audioRef,
    isLoading,
    play,
    onLoadedMetadata,
  } = useAudioPlayerWithCountDown(duration);

  if (!audioNarration?.location) return;

  return (
    <div
      className={cn('flex items-center flex-row', colorClassName, {
        'gap-2': !noStatusText,
      })}
      key={keyID}
    >
      <button
        className={cn('flex items-center h-10', {
          'hover:text-primary-1': !isPlaying,
        })}
        onClick={() => {
          togglePause();
          if (isPlaying) {
            setPlayText(t('continueNarration'));
          } else {
            setPlayText(t('playingNarration'));
          }
        }}
      >
        {isLoading ? (
          <div className="flex z-10 items-center justify-center">
            <AnimateSpinIcon className="z-10 text-black m-auto" color="white" />
          </div>
        ) : isPlaying ? (
          <PauseIcon />
        ) : (
          PlayIcon
        )}

        {noStatusText ? null : playText}
        <AudioNarrationAnimation
          className={cn('h-10 transition-all', {
            'w-10': isPlaying,
            'invisible opacity-0 w-0': !isPlaying,
          })}
          keyID={keyID}
          isPlaying={isPlaying}
          color={animationColor}
        />
      </button>
      <audio
        ref={audioRef}
        onCanPlayThrough={() => {
          onLoadedMetadata();
          play();
        }}
        preload="none"
        onEnded={() => {
          onEnded();
          setPlayText(t('playNarration'));
        }}
        {...(duration && {
          onLoadedMetadata: onLoadedMetadata,
        })}
      >
        <source src={audioNarration.location} />
        Your browser does not support the audio element.
      </audio>
      <div className={'font-sf text-sm font-semibold'}></div>
      <div
        className={cn('font-sf text-sm font-light opacity-80', colorClassName)}
      >
        {timeLeft
          ? formatDuration(timeLeft)
          : duration || formatDuration(audioRef?.current?.duration || '')}
      </div>
    </div>
  );
};
