import React from 'react';
import { CustomPage, Menu, MenuItem } from '@/lib/model';
import Link from 'next/link';
import { getChannelPref } from '@/i18n/userChannel';
import { cn } from '@/lib/utils';

type Props = {
  page?: CustomPage;
  parentPage?: CustomPage;
  menu?: Menu;
  withMarginBottom?: boolean;
};

export async function IntroWrapper({
  page,
  parentPage,
  menu,
  withMarginBottom = true,
}: Props) {
  const { channelPath } = await getChannelPref();

  const menuItems = menu?.primaryMenuItems?.map(item => item as MenuItem);
  const marginBottomClasses = withMarginBottom
    ? `mb-8 lg:mb-10 xl:mb-12 2xl:mb-16`
    : '';

  return (
    <section className="w-full">
      <div className="w-full py-[21px] px-5">
        {parentPage?.name && (
          <h1 className="font-trt text-base text-grey-8 leading-4 pb-1.5 text-center tracking-[1px]">
            {parentPage.name}
          </h1>
        )}
        {page?.name && (
          <h2 className="font-bold text-2xl md:text-4xl lg:text-4.5xl text-black leading-9 text-center">
            {page.name}
          </h2>
        )}
      </div>

      {menu && menuItems && (
        <div className="max-w">
          <ul
            className={cn(
              `px-5 mx-auto flex overflow-x-auto justify-center gap-3.5 py-1.5 border-b border-b-grey-4 border-t border-t-grey-4 ${marginBottomClasses}`,
              { 'border-b-0': !menuItems || !menuItems?.length },
            )}
          >
            {menuItems?.map((item, index) => {
              return (
                <Link key={`${item.id}`} href={`/${channelPath}/${item.slug}`}>
                  <li
                    className={`flex-none gap-3.5 font-notosans text-sm text-grey-10 leading-8 uppercase`}
                  >
                    {index > 0 && (
                      <span className="size-1 rounded-full  bg-grey-7 my-3.5"></span>
                    )}
                    <span className="opacity-85">{item.name}</span>
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      )}

      {page?.description && (
        <div className="w-full px-5 font-notosans font-semibold grey-12 text-sm md:text-base lg:text-xl xl:text-1.5xl xl:leading-7 text-center max-w-2xl pb-6 md:pb-8 xl:pb-12 items-center mx-auto">
          {page.description}
        </div>
      )}
    </section>
  );
}
