import { cn } from '@/lib/utils';
import { HTMLAttributes, ReactNode } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  wrapperClassName?: string;
}

export function PageWrapper({
  children,
  className,
  wrapperClassName,
  ...props
}: Props) {
  return (
    <section className={cn('main-sized-wrapper w-full', className)} {...props}>
      <div
        className={cn(
          'flex flex-col lg:gap-[120px] md:gap-20 sm:gap-16 gap-5',
          wrapperClassName,
        )}
      >
        {children}
      </div>
    </section>
  );
}

export function ArticleSectionWrapper({
  children,
  className,
  ...props
}: Props) {
  return (
    <section className={cn('main-sized-wrapper w-full', className)} {...props}>
      {children}
    </section>
  );
}
