import { HTMLAttributes } from 'react';
import animationData from './audio-narration-animation.json';
import { AnimationData, changeColorInAnimation } from '../setLottieColor';
import { useLottie } from '../useLottie';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isPlaying?: boolean;
  color?: string;
  keyID?: string;
}

export const AudioNarrationAnimation = ({
  isPlaying,
  style,
  className,
  keyID,
  ...props
}: Props) => {
  const { lottieContainer } = useLottie(
    {
      ...props,
      animationData: changeColorInAnimation(
        animationData as AnimationData,
        props.color || '#000000',
      ),
    },
    isPlaying,
  );

  return (
    <div
      key={keyID}
      ref={lottieContainer}
      style={style}
      className={className}
    />
  );
};
