'use client';

import { RadioContentResponse } from '@/lib/api/getRadioPlaylist';
import { Channel, Content } from '@/lib/model';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MediaCard, MediaCardWithTimerRef } from '../home';
import Image from 'next/image';

type RadioContents = RadioContentResponse['RadioContents']['docs'];

export const RadioContent = ({
  radioPlaylistId,
  channel,
  title,
}: {
  radioPlaylistId: string;
  title?: string;
  channel: Channel;
}) => {
  const [contents, setContents] = useState<RadioContents>();
  const [_, setLoading] = useState(true);
  const ref = useRef<MediaCardWithTimerRef>(null);
  const [selectedBlock, setSelectedBlock] = useState<RadioContents[number]>();
  const fetchContents = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const res = await fetch(
          `/api/radio-content?channel=${channel.id}&radioPlaylistId=${id}`,
        );
        const data = (await res.json()) as RadioContentResponse;
        const c = data?.RadioContents?.docs?.map(c => ({
          ...c,
          subType: 'podcastEpisode',
        }));
        setContents(c);
        setSelectedBlock(c?.[0]);
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [channel.id],
  );

  useEffect(() => {
    ref.current?.play();
  }, [selectedBlock?.id]);
  useEffect(() => {
    fetchContents(radioPlaylistId);
  }, [radioPlaylistId, fetchContents]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-[380px_1fr] gap-8 bg-grey-3 p6 sm:p-12">
      <div className="aspect-square w-full">
        {selectedBlock?.id ? (
          <MediaCard
            content={selectedBlock as unknown as Content}
            key={selectedBlock?.id}
            hideDetailPageArrow
            ref={ref}
          />
        ) : (
          <Image
            className="w-full aspect-square"
            src="/png/placeholder.png"
            alt=""
            width={200}
            height={200}
          />
        )}
      </div>
      <div className="h-fit flex flex-col items-start">
        <span className="font-notosans text-grey-13 text-xl line-clamp-2 bg-grey-5 p-[10px] mb-4">
          {title}
        </span>

        <div className="grid gap-bh-4">
          {contents
            ?.filter(el => el.id)
            ?.map(item => {
              return (
                <div
                  key={item?.id}
                  className="grid gap-4 grid-cols-1 sm:grid-cols-[266px_1fr] cursor-pointer"
                  onClick={() => {
                    setSelectedBlock(item);
                  }}
                >
                  <div className="font-extrabold text-black-10 text-xl font-playfair">
                    <div className="overflow-hidden line-clamp-1">
                      {item?.title}
                    </div>
                  </div>
                  <div className="p-2 text-grey-12 text-xl font-notosans">
                    <div className="overflow-hidden line-clamp-1 ">
                      {item?.description}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
