'use client';

import { useEffect, useState } from 'react';
import { VideoCard } from '@/app/components/cards/home';
import {
  ArticleSectionWrapper,
  VideoModal,
  WrapperLabel,
} from '@/app/components/common';
import { Block, Content } from '@/lib/model';
import { getContentsFromBlock } from '@/lib/api';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/app/components/carousel';
import { cn } from '@/lib/utils';
import { EmblaOptionsType } from 'embla-carousel';

type Props = {
  block?: Block;
  carouselItemClassName?: string;
};

const options: EmblaOptionsType = {
  skipSnaps: true,
  align: 'center',
};

export function VideoHorizontalCarousel({
  block,
  carouselItemClassName,
}: Props) {
  const contents = getContentsFromBlock(block);
  const [isHovered, setIsHovered] = useState<Content>();
  const [videoIndex, setVideoIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    function blur() {
      setIsHovered(undefined);
    }
    window.addEventListener('blur', blur);
    return () => {
      window.removeEventListener('blur', blur);
    };
  }, []);

  const handleMouseEnter = (content: Content) => {
    setIsHovered(content);
  };

  const handleMouseLeave = () => {
    setIsHovered(undefined);
  };

  const handleOpenModal = (index: number) => {
    setVideoIndex(index);
    document.querySelectorAll('video').forEach(video => {
      if (video?.pause) {
        video.pause();
      }
    });
  };

  const handleCloseModal = () => {
    setVideoIndex(undefined);
  };

  if (!block) {
    return null;
  }

  return (
    <>
      <section className="flex justify-center w-full">
        <ArticleSectionWrapper className="carousel-wrapper">
          <div
            className="w-full flex flex-col gap-4 lg:gap-6 xl:gap-8"
            onMouseLeave={handleMouseLeave}
          >
            <WrapperLabel
              title={block?.name}
              color="#4E4F4F"
              className="carousel-title"
            />

            <Carousel opts={options}>
              <CarouselContent
                className="gap-4 md:gap-6 xl:gap-8"
                withSidePadding
                sidePaddingClassName="basis-[6px]"
              >
                {contents
                  ?.filter(item => item?.id)
                  ?.map((item, index) => {
                    return (
                      <CarouselItem
                        key={item.id}
                        className={cn(
                          'basis-[78%] sm:basis-5/12 md:basis-[250px] lg:basis-[256px] xl:basis-[302px]',
                          carouselItemClassName,
                        )}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleOpenModal(index)}
                      >
                        <VideoCard
                          content={item}
                          isHover={
                            isHovered?.id === item.id && Boolean(item.media)
                          }
                        />
                      </CarouselItem>
                    );
                  })}
              </CarouselContent>
              <CarouselPrevious className="absolute start-3 text-white hidden md:block" />
              <CarouselNext className="absolute end-3 text-white hidden md:block" />
            </Carousel>
          </div>
        </ArticleSectionWrapper>
      </section>
      <VideoModal
        videoIndex={videoIndex}
        onClose={handleCloseModal}
        initialSlide={contents && videoIndex}
        contents={contents?.filter(item => item?.id) ?? []}
      />
    </>
  );
}
