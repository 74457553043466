import { cn } from '@/lib/utils';
import React, { PropsWithChildren } from 'react';
import { LeftSectionProps } from '../mainLeftRail/types';

interface Props
  extends Pick<LeftSectionProps, 'noBoarderTop'>,
    PropsWithChildren {
  title?: string;
  className?: string;
}

export function OveralWrapperLabel({
  title,
  className,
  noBoarderTop,
  children,
}: Props) {
  return (
    <div
      className={cn(
        `font-notosans text-xl md:text-3xl xl:text-4xl font-black border-t pt-1.5 sm:pt-2`,
        className,
        { '!pt-0 border-none border-t-0': noBoarderTop },
      )}
    >
      {title}
      {children}
    </div>
  );
}
