import { cn } from '@/lib/utils';
import React, { PropsWithChildren } from 'react';
import { LeftSectionProps } from '../mainLeftRail/types';

interface Props
  extends Pick<LeftSectionProps, 'noBoarderTop'>,
    PropsWithChildren {
  title?: string;
  color?: string;
  className?: string;
}

export function WrapperLabel({
  title,
  color,
  className,
  noBoarderTop,
  children,
}: Props) {
  if (!title)
    return (
      <div
        className={cn(`border-t pt-1.5 sm:pt-2`, className, {
          '!pt-0 border-none border-t-0': noBoarderTop,
        })}
        style={{ color: color, borderTopColor: color }}
      />
    );
  return (
    <div
      className={cn(
        `font-notosans text-xl md:text-3xl xl:text-4xl font-black border-t pt-1.5 sm:pt-2`,
        className,
        { '!pt-0 border-none border-t-0': noBoarderTop },
      )}
      style={{ color: color, borderTopColor: color }}
    >
      {title}
      {children}
    </div>
  );
}
