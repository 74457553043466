'use client';

import { BlockElement, Content } from '@/lib/model';
import { ArticleSectionWrapper } from '../../common';
import { EmblaOptionsType } from 'embla-carousel';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../../carousel';
import { cn } from '@/lib/utils';
import { MediaCardRadio } from '../home/MediaCardRadio';

const options: EmblaOptionsType = {
  skipSnaps: true,
  align: 'center',
};

export const RadioPlaylistCard = ({
  blockElements,
  onSelectedBlock,
}: {
  blockElements: BlockElement[];
  onSelectedBlock: (_: BlockElement) => void;
}) => {
  return (
    <ArticleSectionWrapper className="carousel-wrapper mt-20">
      <Carousel opts={options}>
        <CarouselContent
          className={cn('gap-4 md:gap-6 xl:gap-8')}
          withSidePadding
          sidePaddingClassName="basis-[8px]"
        >
          {blockElements
            ?.filter(b => b.radioPlaylist?.id)
            ?.map(item => {
              return (
                <CarouselItem
                  key={item.id}
                  className={cn(
                    'basis-[78%] sm:basis-5/12 md:basis-[250px] lg:basis-[256px] xl:basis-[302px]',
                    //   carouselItemClassName,
                  )}
                  onClick={() => onSelectedBlock(item)}
                >
                  <MediaCardRadio
                    content={item.radioPlaylist as unknown as Content}
                    hideDetailPageArrow
                  />
                </CarouselItem>
              );
            })}
        </CarouselContent>
        <CarouselPrevious className="absolute start-3 text-white hidden md:block" />
        <CarouselNext className="absolute end-3 text-white hidden md:block" />
      </Carousel>
    </ArticleSectionWrapper>
  );
};
